// Import Element Theme
@import '@simpl/element-theme/src/theme';

// Import Element Components
@import '@simpl/element-ng/simpl-element-ng';

// Import the SiMPL Scheduler SCSS styles
$scheduler-composite-background: #f0f0f5; // desired background color for composite view
@import '@simpl/scheduler-ng/simpl-scheduler-ng';

// Override global styles
html {
  font-size: unset;
}

html,
body {
  h4 {
    font-weight: 700;
    font-size: 1rem;
  }

  .epbd-cursor-grab {
    cursor: grab;
  }

  .drop-area {
    display: flex;
    align-items: center;
    justify-content: center;
    // border: 1px dashed $element-text-disabled-light;
    border-radius: 5px;
    width: 100%;
    height: 32px;
    background-color: $element-base-0;
  }

  .modal-x-large {
    max-width: calc(100% - 22px);
  }

  .list-group-border-top {
    border-top: 1px solid var(--element-ui-4);
  }
}

.max-vh-50 {
  max-height: 50vh;
}

.scheduler-view {
  height: calc(100vh - 200px);
}
